import HoveredIcon from "components/HoveredIcon";
import { FC } from "react";

import { ReactComponent as RedOpen } from "assets/icons/boxes/red-open.svg";
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg";
import { useGetSettingsQuery } from "store/api/quickSettingsApi";
import { IconFinderItem } from "types/services/iconFinder";
import findBoxIcon from "utils/findBoxIcon";

interface Props {
  openIconFinder: (item: IconFinderItem) => void;
  isGreen: boolean;
  isSimilar: boolean;
  sales: number;
  currentPrice: number;
  title: string;
  itemID: number;
  images: string;
  productUrl: string;
  alwaysShowAli?: boolean;
}

const IconFinderBox: FC<Props> = ({
  openIconFinder,
  isGreen,
  isSimilar,
  sales,
  currentPrice,
  title,
  itemID,
  images,
  productUrl,
  alwaysShowAli = false
}) => {
  const { data } = useGetSettingsQuery();
  const findIcon = findBoxIcon(alwaysShowAli ? "Ali" : data?.result.amazonsite || "");

  const handleClick = () => {
    openIconFinder({
      sold: sales,
      price: currentPrice,
      title,
      id: itemID,
      img: images,
      url: productUrl,
      isSimilar: isSimilar,
      isGreen: isGreen,
    });
  };
  const showBox =
    data?.result.amazonsite === "Baba" ||
    data?.result.amazonsite === "Cjd" ||
    data?.result.amazonsite === "Ali" || alwaysShowAli;
  const iconColor = !isGreen && !showBox ? "fill-errorRed" : "dark:fill-grey100 fill-darkBlue";

  const tooltipTitle =
    !showBox && !isGreen ? "Product not found, add product ID manually" :
      data?.result.amazonsite === "Ali" ? "Find product by Image on AliExpress " :
        data?.result.amazonsite === "Baba" || data?.result.amazonsite === "Cjd" ? "Find product by Image on Alibaba" :
          `${isSimilar ? "Similar" : "Exact"} product found on ${data?.result.amazonsite === "WAL" ? "Walmart" : "Amazon"}`;


  return showBox || isGreen ? (
    <div className="relative w-fit">
      {!showBox && !isSimilar ? (
        <div className="absolute bg-spaceGreen right-1 top-0 w-[15px] h-[15px] flex items-center justify-center rounded-full">
          <Checkmark className="fill-darkBlue w-2 h-1.5" />
        </div>
      ) : ""}
      <HoveredIcon
        Icon={findIcon.icon}
        className="w-9 h-9"
        HoveredIcon={findIcon.iconHovered}
        iconClassName={findIcon.iconClassName}
        hoveredIconClassName={findIcon.iconClassName}
        iconColor={iconColor}
        handleClick={handleClick}
        title={tooltipTitle}
      />
    </div>
  ) : (
    <HoveredIcon
      Icon={RedOpen}
      iconColor={iconColor}
      handleClick={handleClick}
      title={tooltipTitle}
    />
  );
};

export default IconFinderBox;
