export enum PLAN_TYPE {
  PRO = "Pro",
  PRO_PLUS = "ProPlus",
  ENTERPRISE = "Enterprise",
}
export interface PlansType {
  period: string;
  planList: PlanList[];
}

export interface TSelectedPlan {
  type: string;
  planName: string;
  planPrice: number;
  skip: boolean;
}

export interface ShopifyPlanList {
  type: string;
  name: string;
  id: string;
  skipAndSave: number;
  isDiscount: boolean;
  description: string;
  price: number;
  discountedPrice: number;
  isYearly: boolean;
}
export interface PlanList {
  type: string;
  planName: string;
  planId: string;
  isDiscount?: boolean;
  planDescription: string;
  planPrice: number;
  planDiscountPrice: number;
  upcampaignPrice?: number;
  highlight: boolean;
  skipAmout?: number;
}

export interface PlanComparisonItemType {
  icon: string;
  title: string;
  description: string;
  pro: boolean | "limited";
  proPlus: boolean | "limited";
  enterprise: boolean | "limited";
}
