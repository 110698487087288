import { ReactComponent as CategoryResearch } from "assets/icons/category-research.svg";
import { ReactComponent as CompetitorResearch } from "assets/icons/competitor-research.svg";
import { ReactComponent as ManagementPanel } from "assets/icons/management-panel.svg";
import { ReactComponent as MyCompetitors } from "assets/icons/my-competitors.svg";
import { ReactComponent as MyProducts } from "assets/icons/my-products.svg";
import { ReactComponent as ProductExplorer } from "assets/icons/product-exlorer.svg";
import { ReactComponent as ProductResearch } from "assets/icons/product-research.svg";
import { ReactComponent as QuickSettings } from "assets/icons/quick-settings.svg";
import { ReactComponent as StoreFinder } from "assets/icons/store-finder-shopify.svg";
import { ReactComponent as Store } from "assets/icons/store.svg";
import { ReactComponent as TitleBuilder } from "assets/icons/title-builder.svg";
import { ReactComponent as TutorialsTraining } from "assets/icons/tutorials-training.svg";
import { ReactComponent as Video } from "assets/icons/video.svg";
import { ReactComponent as ZikPro } from "assets/icons/zik-pro.svg";
import { PATH } from "routes/path";

export const sidebarItemsEbay = [
  {
    title: "Product Research",
    translate: "ProductResearch",
    path: PATH.productResearch.root,
    Icon: ProductResearch,
    iconStyles: "w-4.5 h-4.5",
    smIconStyles: "w-[13px] h-[13px]",
  },
  {
    title: "Competitor Research",
    translate: "CompetitorResearch",
    path: PATH.competitorResearch.root,
    Icon: CompetitorResearch,
    iconStyles: "w-4.5 h-[19.5px]",
    smIconStyles: "w-[13px] h-[14.08px]",
  },
  {
    title: "Category Research",
    translate: "CategoryResearch",
    path: PATH.categoryResearch.root,
    Icon: CategoryResearch,
    iconStyles: "w-4.5 h-[16.5px]",
    smIconStyles: "w-[13px] h-[11.92px]",
  },
  {
    title: "Title Builder",
    translate: "TitleBuilder",
    redirect: process.env.REACT_APP_TITLE_BUILDER,
    path: PATH.titleBuilder.root,
    Icon: TitleBuilder,
    iconStyles: "w-4.5 h-[16.5px]",
    smIconStyles: "w-[13px] h-[11.92px]",
  },
  {
    title: "Zik Pro",
    translate: "ZIKPro",
    path: PATH.zikPro.root,
    Icon: ZikPro,
    width: "w-[15.375rem]",
    iconStyles: "w-5 h-4.5",
    smIconStyles: "w-[14.99px] h-[13.2px]",
    hasBottomDivider: true,
    submenu: [
      {
        title: "Bulk Scanner",
        translate: "BulkScanner",
        path: PATH.zikPro.zikProBulkScanner,
      },
      {
        title: "500 Best selling eBay items",
        translate: "500BestsellingitemsonEbay",
        path: PATH.zikPro.bestSellingItems,
      },
      {
        title: "Ali Growth Scanner",
        translate: "AliGrowthScanner",
        path: PATH.zikPro.aliGrowthScanner,
      },
      {
        title: "Turbo Scanner",
        translate: "TurboScanner",
        path: PATH.zikPro.turboScanner.root,
        limited: true,
      },
      {
        title: "Autopilot",
        translate: "Autopilot",
        path: PATH.zikPro.autopilot,
        limited: true,
      },
    ],
  },
  {
    title: "My Competitors",
    translate: "MyCompetitors",
    path: PATH.myCompetitors,
    Icon: MyCompetitors,
    iconStyles: "w-[21px] h-[21px]",
    smIconStyles: "w-[15.17px] h-[15.17px]",
  },
  {
    title: "My Products",
    translate: "MyProducts",
    path: PATH.myProducts.root,
    Icon: MyProducts,
    iconStyles: "w-[17.5px] h-4",
    smIconStyles: "w-[13px] h-[11.92px]",
  },
  {
    title: "Tutorials & Training",
    translate: "TutorialsTraining",
    path: PATH.tutorials,
    Icon: TutorialsTraining,
    iconStyles: "w-6 h-[15px]",
    width: "w-[10.5rem]",
    smIconStyles: "w-[17.33px] h-[10.83px]",
    hasBottomDivider: true,
    submenu: [
      {
        title: "Academy",
        translate: "Academy",
        path: PATH.academy,
        Icon: TutorialsTraining,
      },
      {
        title: "Tutorials",
        translate: "Tutorials",
        path: PATH.tutorials,
        Icon: Video,
      },
    ],
  },
  {
    title: "Quick Settings",
    translate: "QuickSettings",
    path: PATH.quickSettings.root,
    Icon: QuickSettings,
    iconStyles: "w-[19.5px] h-[21px]",
    quickSettings: true,
    smIconStyles: "w-[14.08px] h-[15.17px]",
  },
  {
    title: "Management Panel",
    translate: "ManagementPanel",
    path: PATH.managementPanel.root,
    Icon: ManagementPanel,
    iconStyles: "w-[21px] h-[21px]",
    width: "w-[14.0625rem]",
    smIconStyles: "w-[15.17px] h-[15.17px]",
    submenu: [
      {
        title: "My Saved Searches",
        translate: "MySavedSearches",
        path: PATH.managementPanel.savedSearches,
      },
      /*   {
        title: "Waiting List",
        translate: "WaitingList",
        path: PATH.managementPanel.waitingList,
      }, */
      {
        title: "Manage Payments",
        translate: "ManagePayments",
        path: PATH.managementPanel.managePayments,
      },
      {
        title: "Affiliate Manager",
        translate: "AffiliateManager",
        path: PATH.managementPanel.affiliateManager,
      },
      {
        title: "Store Analytics",
        translate: "StoreAnalytics",
        path: "",
        submenu: [
          {
            title: "+ Add Store",
            translate: "StoreAnalytics",
            Icon: Store,
            path: PATH.managementPanel.storeAnalytics,
          },
        ],
      },
      {
        title: "Advanced Settings",
        translate: "Advanced Settings",
        path: PATH.quickSettings.account,
      },
    ],
  },
];

export const sidebarItemsShopify = [
  {
    title: "Sales Tracker",
    translate: "Store Sales Tracker",
    path: PATH.shopify.salesSpy,
    Icon: CompetitorResearch,
    iconStyles: "w-4.5 h-[19.5px]",
    smIconStyles: "w-[13px] h-[14.08px]",
  },
  {
    title: "Store Finder",
    translate: "Store Finder",
    path: PATH.shopify.storeFinder,
    Icon: StoreFinder,
    iconStyles: "w-[20px] h-[23px]",
    smIconStyles: "w-[13px] h-[13px]",
  },
  {
    title: "Product Explorer",
    translate: "Product Explorer",
    path: PATH.shopify.productExplorer,
    Icon: ProductExplorer,
    iconStyles: "w-[21px] h-[22.5px]",
    smIconStyles: "w-[13px] h-[11.92px]",
  },
  {
    title: "Title Builder",
    translate: "TitleBuilder",
    redirect: process.env.REACT_APP_TITLE_BUILDER,
    path: PATH.titleBuilder.root,
    Icon: TitleBuilder,
    iconStyles: "w-4.5 h-[16.5px]",
    smIconStyles: "w-[13px] h-[11.92px]",
    hasBottomDivider: true,
  },
  {
    title: "Tutorials & Training",
    translate: "TutorialsTraining",
    path: PATH.academy,
    Icon: TutorialsTraining,
    iconStyles: "w-6 h-[15px]",
    width: "w-[10.5rem]",
    smIconStyles: "w-[17.33px] h-[10.83px]",
    hasBottomDivider: true,
    submenu: [
      {
        title: "Academy",
        translate: "Academy",
        path: PATH.academy,
        Icon: TutorialsTraining,
      },
      {
        title: "Tutorials",
        translate: "Tutorials",
        path: PATH.tutorials,
        Icon: Video,
      },
    ],
  },
  {
    title: "Quick Settings",
    translate: "QuickSettings",
    path: PATH.quickSettings.root,
    Icon: QuickSettings,
    iconStyles: "w-[19.5px] h-[21px]",
    quickSettings: true,
    smIconStyles: "w-[14.08px] h-[15.17px]",
  },
  {
    title: "Management Panel",
    translate: "ManagementPanel",
    path: PATH.managementPanel.root,
    Icon: ManagementPanel,
    iconStyles: "w-[21px] h-[21px]",
    width: "w-[156px]",
    smIconStyles: "w-[15.17px] h-[15.17px]",
    submenu: [
      {
        title: "Manage Payments",
        translate: "ManagePayments",
        path: PATH.shopify.billing,
      },
      {
        title: "Affiliate Manager",
        translate: "AffiliateManager",
        path: PATH.managementPanel.affiliateManager,
      },

      {
        title: "Advanced Settings",
        translate: "Advanced Settings",
        path: PATH.quickSettings.account,
      },
    ],
  },
];

export const sidebarCities = [
  {
    title: "Los Angeles",
    timezone: "America/Los_Angeles",
    id: "los_angeles",
  },
  {
    title: "Tel Aviv",
    timezone: "Asia/Tel_Aviv",
    id: "tel_aviv",
  },
  {
    title: "London",
    timezone: "Europe/London",
    id: "london",
  },
  {
    title: "Berlin",
    timezone: "Europe/Berlin",
    id: "berlin",
  },
  {
    title: "Manila",
    timezone: "Asia/Manila",
    id: "manila",
  },
  {
    title: "New York",
    timezone: "America/New_York",
    id: "new_york",
  },
];
export const sidebarCountries = [
  {
    title: "USA",
    timezone: "USA",
    id: "USA",
    cities: [
      {
        title: "New York",
        timezone: "America/New_York",
        id: "new_york",
      },
      {
        title: "Los Angeles",
        timezone: "America/Los_Angeles",
        id: "los_angeles",
      },
    ],
  },

  {
    title: "UK",
    timezone: "Europe/London",
    id: "london",
  },
  {
    title: "Germany",
    timezone: "Europe/Berlin",
    id: "berlin",
  },
  {
    title: "Philippines",
    timezone: "Asia/Manila",
    id: "manila",
  },
  {
    title: "Portugal",
    timezone: "Europe/Lisbon",
    id: "Lisbon",
  },
  {
    title: "Israel",
    timezone: "Asia/Tel_Aviv",
    id: "tel_aviv",
  },
];
