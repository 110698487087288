import { Table as ReactTable } from "@tanstack/react-table";
import { ReactComponent as Exclamation } from "assets/icons/exclamation.svg";
import { SortDir } from "hooks/useSort";
import "styles/table.css";
import { TableStyles } from "./Table";
import TableBodyRow from "./TableBodyRow";
import TableEmpty from "./TableEmpty";
import TableHeadRow from "./TableHeadRow";

interface Sort {
  direction: SortDir;
  column: string;
}
interface Props<T> {
  table: ReactTable<T>;
  sort?: Sort
  updateColWidth?: string;
  isLoading?: boolean;
  hideTableHead?: boolean;
  hideTableBody?: boolean;
  styles?: TableStyles;
  infoMsg?: string;
  emptyTableMsg?: string;
  alignBodyLeft?: boolean;
  customLoading?: JSX.Element;
}

const Table = <T extends object = {}>({
  table,
  styles,
  sort,
  isLoading,
  hideTableHead,
  infoMsg,
  updateColWidth,
  hideTableBody,
  alignBodyLeft,
  emptyTableMsg,
  customLoading
}: Props<T>) => {
  const tableStyles = `w-full h-full table-auto border-separate border-spacing-y-2 text-xs -mt-2 text-darkBlue dark:text-grey100 ${styles?.minWidth || "min-w-440px"
    } ${styles?.tableClassName || ""}`;

  const { rows } = table.getRowModel();
  const headerGroups = table.getHeaderGroups();
  return (
    <div
      className={`w-full  ${styles?.px || "px-4"
        }  ${styles?.style || ""}`}
    >
      <table className={tableStyles} style={{ width: "100%", position: infoMsg ? "relative" : "static" }}>
        <thead className={hideTableHead ? "hidden" : ""}>
          {headerGroups.map((headerGroup, i) => (
            <TableHeadRow<T>
              sort={sort}
              headerGroup={headerGroup}
              updateColWidth={updateColWidth}
              key={i}
              getColumn={table.getColumn}
              styles={styles && styles.head}
            />
          ))}
        </thead>
        {infoMsg ? (
          <tr className={`h-4 ${styles?.infoMsg?.wrapper}`}>
            <div className={`flex items-center pl-[14.6px] w-full bg-neutral2 dark:bg-neutral3 rounded-[5px] gap-2.5 absolute h-5.5 top-[78px] ${styles?.infoMsg?.inner}`}>
              <Exclamation className="fill-darkBlue dark:fill-deepSpace900 min-w-[13px] h-[13px]" />
              <span className="block w-full dark:text-deepSpace900">
                {infoMsg}
              </span>
            </div>
          </tr>
        ) : ""}
        <tbody
          className={`${hideTableBody ? "hidden" : ""}  ${rows.length > 0 ? "" : "relative"
            } ${isLoading ? "relative" : ""} table-comp-body`}
        >
          {customLoading ?
            rows.map((row, i) => (
              <TableBodyRow<T>
                alignBodyLeft={alignBodyLeft}
                row={row}
                key={i}
                styles={styles && styles.body}
              />
            ))
            : rows.length > 0 && !isLoading
              ? rows.map((row, i) => (
                <TableBodyRow<T>
                  alignBodyLeft={alignBodyLeft}
                  row={row}
                  key={i}
                  styles={styles && styles.body}
                />
              ))
              : headerGroups.map((headerGroup, i) => (
                <TableEmpty<T>
                  styles={styles && (styles.empty || styles.body)}
                  headerGroup={headerGroup}
                  emptyTableMsg={emptyTableMsg}
                  isLoading={isLoading || false}
                  key={i}
                />
              ))}
          {customLoading && isLoading ? customLoading : ""}
          {/*   {rows.length > 0 && !isLoading
            ? rows.map((row, i) => (
              <TableBodyRow<T>
                row={row}
                key={i}
                styles={styles && styles.body}
              />
            ))
            :
            headerGroups.map((headerGroup, i) => (
              <TableEmpty<T>
                styles={styles && (styles.empty || styles.body)}
                headerGroup={headerGroup}
                emptyTableMsg={emptyTableMsg}
                isLoading={isLoading || false}
                key={i}
              />
            ))} */}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
